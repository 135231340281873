<template>
  <div>
    <p class="f22 f-center" style="padding: 0">任务明细</p>
    <div class="mt20" v-loading="loading">
      <el-table :data="lists" border style="width: 100%">
        <!-- <el-table-column prop="cc_id" label="任务ID" width="80">
        </el-table-column> -->
        <el-table-column prop="cc_id" label="任务编号"> </el-table-column>
        <el-table-column prop="order_no" label="订单号" width="200">
        </el-table-column>
        <el-table-column prop="channel_title" label="来源"> </el-table-column>
        <el-table-column prop="titles2" label="二级来源"> </el-table-column>
        <el-table-column prop="contacts" label="联系人"> </el-table-column>
        <el-table-column prop="telephone" label="联系电话"> </el-table-column>
        <el-table-column prop="addres" label="地址" width="200">
        </el-table-column>
        <el-table-column prop="username" label="店长"> </el-table-column>
        <el-table-column prop="store_name" label="店铺"> </el-table-column>
        <el-table-column prop="entry" label="任务类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">客户跟进任务</span>
            <span v-if="scope.row.type == 2">上门跟进任务</span>
            <span v-if="scope.row.type == 3">签约跟进任务</span>
            <span v-if="scope.row.type == 4">施工跟进任务</span>
            <span v-if="scope.row.type == 8">取消挽回任务</span>
            <span v-if="scope.row.type == 9">退单挽回任务</span>
            <span v-if="scope.row.type == 7">完工回访任务</span>
            <span v-if="scope.row.type == 20 || scope.row.type == 0"
              >订单指派任务</span
            >
            <span v-if="scope.row.type == 10">订单投诉任务</span>
          </template>
        </el-table-column>
        <el-table-column prop="entry" label="操作人员"> </el-table-column>
        <el-table-column label="任务状态">
          <template slot-scope="scope">
            <span v-if="scope.row.TaskStatus == 0" style="color: red"
              >未处理</span
            >
            <span v-else style="color: green">已处理</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="doDetail(scope.row)"
              >详 情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      @open="diagopen()"
      append-to-body
      @close="diagclose()"
    >
      <Detailes></Detailes>
    </el-dialog>
  </div>
</template>
<script>
import detailes from "@/components/detailes.vue";
export default {
  props: ["currentPage3"],
  data() {
    return {
      lists: [],
      loading: true,
      taskStatus: 3,
      // 弹框
      dialogTableVisible: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisible = this.$store.state.dialogTableVisible;
      this.isdiag = this.$store.state.dialogTableVisible;
    },
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/Cc/detailed", {
          taskStatus: this.taskStatus,
        })
        .then((res) => {
          if (res.code == 200) {
            this.lists = res.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 点击详情打开
    doDetail(row) {
      if (row) {
        this.$store.commit("setOrder", row);
        // //console.log(this.$store.state.order_id+'----------------------')
      }
      this.$store.commit("setMessage", true);
      this.dialogTableVisible = true;
    },
    diagopen() {
      this.$store.commit("setMessage", true);
    },
    diagclose() {
      this.$store.commit("setMessage", false);
      this.$emit("getDataAgain", this.currentPage3);
      this.$emit("update:currentPage3", this.currentPage3);
    },
  },
  components: {
    Detailes: detailes,
  },
};
</script>
